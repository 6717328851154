import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TopHeading } from '../components/heading';

class PrivacyPage extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                hasGerman={true}
                slug={this.props.path}
            >
                <SEO title="Privacy Policy" />
                <TopHeading>Privacy Policy</TopHeading>
                <article>
                    <h2>Short version</h2>
                    <p>
                        This site is static and don't collect any more data than
                        the bare minimum. I'm only interested in the general
                        usage statistics of this site. Because of this I don't
                        use Analytics that collect client side information. The
                        only data that will be saved is a log entry with an
                        anonymized IP Adress, your User-Agent, the time of the
                        request and the requested resource.
                    </p>

                    <h2>All details</h2>
                    <strong>
                        Please referr to the german version for all details.
                    </strong>
                </article>
            </Layout>
        );
    }
}

export default PrivacyPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
